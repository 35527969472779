import React from 'react';
import PropTypes from 'prop-types';

import {
  triggerGA,
  relStringGenerator,
  targetStringGenerator,
  getAnchorId
} from '../../../utils/functions';

import { Primary, Secondary } from './BaseButtonStyles';

function BaseButton(props) {
  const {
    buttonType, // defines styles of button
    dataTest,
    src,
    className,
    target,
    gaCategory,
    gaLabel,
    id,
    children,
    ariaLabel,
    event,
    eventProp,
    as
  } = props;

  // Generate onClickHandler
  const onClickHandler = () => {
    if (gaCategory && gaLabel) {
      triggerGA(gaCategory, gaLabel);
    }
    // If we need additional scripts to fire off onClick in the future,
    // We can add a key to the json, check for them, and add the needed logic
    // Below this comment. so it will fire in the onClickHandler fires
    // Important to note, we don't pass in variables via the onClick function,
    // it grabs straight from the props above
  };
  // Below starts returning button based on buttonType.
  // all same functionality, just wraps in different styled component.

  // hollow button with grey outline and grey text

  if (buttonType === 'secondary') {
    return (
      <Secondary
        as={as || null}
        aria-label={ariaLabel || null}
        href={src}
        data-test={`${dataTest}` ? `${dataTest}` : `${target}`}
        data-event={event}
        data-event-prop={eventProp}
        className={className || null}
        id={getAnchorId(id)}
        onClick={onClickHandler}
        rel={relStringGenerator(target)}
        target={targetStringGenerator(target)}
      >
        {children}
      </Secondary>
    );
  }

  // Defaults to orange primary button
  return (
    <Primary
      as={as || null}
      aria-label={ariaLabel || null}
      className={className || null}
      href={src || null}
      data-test={`${dataTest}` ? `${dataTest}` : `${target}`}
      data-event={event}
      data-event-prop={eventProp}
      id={getAnchorId(id)}
      onClick={onClickHandler}
      rel={relStringGenerator(target)}
      target={targetStringGenerator(target)}
    >
      {children}
    </Primary>
  );
}

BaseButton.propTypes = {
  event: PropTypes.string,
  eventProp: PropTypes.string,
  ariaLabel: PropTypes.string,
  buttonType: PropTypes.string,
  dataTest: PropTypes.string,
  src: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  gaLabel: PropTypes.string,
  gaCategory: PropTypes.string,
  children: PropTypes.node,
  as: PropTypes.string
};

BaseButton.defaultProps = {
  event: null,
  eventProp: null,
  ariaLabel: null,
  buttonType: 'primary',
  dataTest: '',
  className: '',
  src: null,
  target: '',
  id: '',
  gaLabel: null,
  gaCategory: null,
  as: null,
  children: null
};

export default BaseButton;
