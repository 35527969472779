import React from 'react';
import styled from 'styled-components';
import BaseLink from './Shared/buttons/BaseLink';

const runtime = typeof document === 'object';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.blue_10};
  min-height: 4.4rem;
  position: relative;
  z-index: 100;
`;

const Text = styled.div`
  text-align: center;
  padding: 1rem 5rem;

  & p {
    font-size: 14px !important;
    color: ${(props) => props.theme.colors.grey_90};
  }

  & a {
    display: inline;
    font-size: 14px !important;
    white-space: nowrap;
  }
`;

const CloseButton = styled.span`
  position: absolute;
  right: 16px;
  top: 6px;
  background-repeat: no-repeat;
  cursor: pointer;

  & img {
    border-radius: ${(props) => props.theme.borderRadius.small};
    opacity: 0.6;
    padding: 8px;
    transition: ${(props) => props.theme.transitions.all};
  }

  & img:hover {
    background-color: #e6e6e6;
    opacity: 1;
    transition: ${(props) => props.theme.transitions.all};
  }
`;

const handleHelloClick = () => {
  const bar = document.getElementById('message-bar-alert-top');
  const track = bar.getAttribute('data-track');

  return (
    track &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', 'Hello bar', 'Click', track])
  );
};

class Hello extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBar: false,
      title: '',
      text: '',
      type: '',
      url: '',
      track: ''
    };
    // if user clicks x, disable hello bar and update local storage
    // this.closeBar = (id) => {
    //   localStorage.setItem('hello', id);
    //   localStorage.setItem('hellocount', '3');
    //   this.setState({ showBar: false });
    // };
    // open if bar count is less than three
    // this.openBar = (id) => {
    //   const barValue = localStorage.getItem('hello');
    //   let barCount = JSON.parse(localStorage.getItem('hellocount'));

    //   if (barValue !== id) {
    //     barCount += 1;
    //     if (barCount > 3) {
    //       this.closeBar(id);
    //     } else {
    //       localStorage.setItem('hellocount', JSON.stringify(barCount));
    //       this.setState({ showBar: true });
    //     }
    //   } else {
    //     this.setState({ showBar: false });
    //   }
    // };
  }

  componentDidMount() {
    if (runtime) {
      window.pmt('log', [
        {
          '[Hello]': `disabled for ${document.location.host}`
        }
      ]);
    }
  }

  render() {
    const { showBar, text, title, type, url, track } = this.state;
    if (title && showBar === true) {
      return (
        <Container
          id="message-bar-alert-top"
          onClick={handleHelloClick}
          data-track={track}
        >
          <Text className="align-middle">
            <p
              className="mb-0 mr-2 d-inline"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <BaseLink
              onClick={() => this.closeBar(title)}
              target={type}
              src={url}
              linkType="arrowLink"
            >
              {text}
            </BaseLink>
          </Text>
          <CloseButton onClick={this.closeBar}>
            <img
              alt="X"
              src="https://voyager.postman.com/icon/x-icon-grey.svg"
            />
          </CloseButton>
        </Container>
      );
    }
    return null;
  }
}

export default Hello;
