export const getAnchorId = (prop) => {
  if (prop) {
    const anchorTag = prop.replace(/\s+/g, '-').replace(/\?/g, '').toLowerCase();
    return anchorTag;
  }
  return null;
};

// Get href
export const getHref = (a) => {
  let { href } = a;
  const isLocal = href && !!href.match(document.location.host);

  if (isLocal) {
    href = href.split(document.location.host);
    href.shift();
    href = href.join('/');
  }

  return {
    url: href,
    isLocal
  };
};

// Get a tag
export const getAnchorTag = (target) => {
  let a = target;
  let tagName;

  for (let i = 0; i < 10; i + 1) {
    tagName = a.tagName.toLowerCase();

    if (tagName === 'a') {
      i = 10;
    } else {
      a = a.parentNode;
    }
  }

  return a;
};

// Google Analytics actions (Applied to Link or Button)
export const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pm &&
    window.pm.ga('send', 'event', category, 'Click', label)
  );
};

// Helper function for rel attribute in link or button
export function relStringGenerator(target) {
  if (target === 'new-tab-postman' || target === 'external-postman') {
    return 'noopener';
  }
  if (target === 'new-tab-external-company' || target === 'external-other') {
    return 'noopener noreferrer';
  }
  if (
    target === 'new-tab-external-nofollow' ||
    target === 'external-nofollow'
  ) {
    return 'noopener noreferrer nofollow';
  }

  return null;
}

// Helper function for target attribute in link or button
export function targetStringGenerator(target) {
  const newTabs = [
    'new-tab-postman',
    'new-tab-postman',
    'new-tab-external-company',
    'external-other',
    'new-tab-external-nofollow',
    'new-tab-external-nofollow'
  ];

  for (let i = 0; i < newTabs.length; i += 1) {
    if (newTabs[i] === target) {
      return '_blank';
    }
    if (target === 'same-tab') {
      return '_self';
    }
  }

  return '_self';
}
