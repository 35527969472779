import styled from 'styled-components';
import { theme } from '../../Theme';

// Can import JUST the styles (if need to style dangerouslySet a tags for example) by importing the styled component below
// Blue link, inline link
export const BaseLinkStyles = styled.a`
  color: ${theme.colors.blue_60};
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid ${theme.colors.blue_60};
    cursor: pointer;
    transition: ${theme.transitions.all};
  }
`;

// Arrow Link
export const ArrowLinkStyles = styled(BaseLinkStyles)`
  &:hover,
  :active {
    border-bottom: 1px solid
      ${(props) =>
        props.className ? theme.colors[props.className] : theme.colors.blue_60};
    color: ${theme.colors.blue_60};
    text-decoration: none;
    transition: ${theme.transitions.all};
  }
`;

// Footer Link
export const FooterLinkStyles = styled(BaseLinkStyles)`
  color: ${theme.colors.grey_50};
  display: block;
  line-height: 1.7;
  border-bottom: none;

  &:hover,
  :active {
    border-bottom: none !important;
    line-height: 1.7;
    display: block;
  }
`;
