import React from 'react';
import styled from 'styled-components';
import { Divider } from 'aether-marketing';
import PropTypes from 'prop-types';
import BaseButton from './buttons/BaseButton';
import BaseLink from './links/BaseLink';

const Section = styled.section`
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
    'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
background-color: ${(props) => props.backgroundColor || 'white'};
  padding: 40px 0px;
  margin: auto;
`;

const OrangeLine = styled.div`
  margin: 0;
  width: 50px;
  height: 4px;
  background-color: #f26b3a;
  margin: 0 auto;
`;

function SideBySide({
  layout,
  title,
  body,
  divider,
  media,
  button,
  titleLine,
  anchorId,
  backgroundColor,
  link
}) {
  // Determine column widths

  const layoutString = layout.toUpperCase();
  let colText;
  let colMedia;
  switch (layoutString) {
    case '1R':
      colMedia = 'col-lg-6 offset-lg-0 mb-5 mb-lg-0';
      colText = 'col-lg-5 offset-lg-1 col-md-8 offset-md-2';
      break;
    case '1L':
      colText =
        'col-12 col-lg-5 order-1 order-lg-0 offset-lg-0 align-items-center';
      colMedia = 'col-10 col-lg-6 order-0 offset-1 order-lg-1 offset-lg-1';
      break;
    default:
      colText = 'col-12 col-lg-5 offset-lg-0 align-items-center';
      colMedia = 'col-12 col-lg-6 offset-lg-1';
  }
  const textSection = (
    <div className={`${colText}`}>
      <h2 className="text-center">{title}</h2>
      {titleLine && <OrangeLine className="mb-3" />}
      {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
      {button && (
        <div className="mb-4 mr-3 d-inline">
          <BaseButton
            buttonType={button.buttonType}
            as="a"
            src={button.src}
            target={button.target}
            id={button.id}
            gaCategory={button.gaCategory}
            gaLabel={button.gaLabel}
            className={button.type === 'inline' ? 'pl-0' : null}
            dataTest={button.id}
          >
            {button.text}
          </BaseButton>
        </div>
      )}
      {(
        link && (
          <BaseLink
            linkType={link.linkType}
            src={link.src}
            target={link.target}
            id={link.id}
            gaCategory={link.gaCategory}
            gaLabel={link.gaLabel}
            ariaLabel={link.ariaLabel}
            event={link.event}
            eventProp={link.eventProp}
          >
            {link.text}
          </BaseLink>
        )
      )}
    </div>
  );

  const mediaSection = (
    <div className={`${colMedia} mb-3 mb-lg-0`}>
      <img alt={media.alt} src={media.src} className="img-fluid" />
    </div>
  );

  return (
    <>
      <Section id={anchorId} backgroundColor={backgroundColor}>
        <div className="container">
          {layoutString === '1R' && (
            <div className="row align-items-center">
              {mediaSection}
              {textSection}
            </div>
          )}
          {layoutString === '1L' && (
            <div className="row align-items-center">
              {textSection}
              {mediaSection}
            </div>
          )}
        </div>
      </Section>
      {divider && <Divider />}
    </>
  );
}

export default SideBySide;
SideBySide.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  titleLine: PropTypes.bool,
  anchorId: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  divider: PropTypes.bool,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string,
    border: PropTypes.string
  }),
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  link: PropTypes.shape({
    linkType: PropTypes.string,
    src: PropTypes.string,
    target: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.string,
    gaCategory: PropTypes.string,
    gaLabel: PropTypes.string,
    ariaLabel: PropTypes.string,
    event: PropTypes.string,
    eventProp: PropTypes.string
  }),
};

SideBySide.defaultProps = {
  layout: '1L',
  title: null,
  titleLine: false,
  anchorId: null,
  body: null,
  divider: false,
  button: null,
  link: null,
  media: null
};
